'use client';
import IMAGES from '@/utils/validations/ImagePaths';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Grid, Link, Stack, Typography, styled } from '@mui/material';
import Image from 'next/image';
const FooterContainer = styled('footer')({
  backgroundImage: 'linear-gradient(to right, #FFB300, #C62828)',
  padding: '1rem 0',
});

const FooterItem = styled('div')({
  marginBottom: '-0.5rem',
});

const FooterIcon = styled('span')({
  marginRight: '0.5rem',
});

const Footer = () => {
  return (
    <Stack>
      <FooterContainer>
        {/* <Container maxWidth="lg"> */}
        <Grid
          container
          spacing={2}
          justifyContent={'space-evenly'}
          alignItems="center"
          alignContent={'center'}
        >
          <Grid item xs={12} sm={6} md={4}>
            <FooterItem>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Lexend', sans-serif",
                  fontSize: '15px',
                  fontWeight: 700,
                  lineHeight: '30px',
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                <Image
                  src={IMAGES?.NN}
                  alt="NN Logo"
                  height={100}
                  width={100}
                />
              </Typography>
            </FooterItem>
          </Grid>
          <Grid item xs={10} sm={6} md={4}>
            <FooterItem>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Lexend', sans-serif",
                  fontSize: '15px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  textAlign: 'center',
                  color: 'white',

                  wordSpacing: '0.5em',
                }}
              >
                Address: 135, 3CR5+977 VCNR Towers, BH Rd, opp. Binnamangala
                Nursery, Vinayaka Nagar, Sadashiva Nagara, Nelamangala Town,
                Nagarur, Karnataka 562123
              </Typography>
            </FooterItem>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FooterItem>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0.1}
              >
                <Link
                  href="#"
                  sx={{
                    fontfamily: "'Lexend', sans-serif",
                    fontSize: '15px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    textAlign: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    display: 'block',
                  }}
                >
                  <MailOutlinedIcon />
                </Link>
                <Link
                  href="#"
                  sx={{
                    fontfamily: "'Lexend', sans-serif",
                    fontSize: '15px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    textAlign: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    display: 'block',
                  }}
                >
                  abc@gmail.com
                </Link>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0.1}
              >
                <Link
                  href="#"
                  sx={{
                    fontfamily: "'Lexend', sans-serif",
                    fontSize: '15px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    textAlign: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    display: 'block',
                  }}
                >
                  <PhoneEnabledOutlinedIcon />
                </Link>
                <Link
                  href="#"
                  sx={{
                    fontfamily: "'Lexend', sans-serif",
                    fontSize: '15px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    textAlign: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    display: 'block',
                  }}
                >
                  +91 987654321
                </Link>
              </Stack>
            </FooterItem>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display={'flex'}
            justifyContent={'center'}
          >
            <FooterItem>
              {/* <Link href="#">
                <FooterIcon>
                  <WhatsAppIcon style={{ color: 'white' }} />
                </FooterIcon>
              </Link> */}
              <Link
                href="https://www.youtube.com/@namastenelamangala"
                target="_blank"
              >
                <FooterIcon>
                  <YouTubeIcon style={{ color: 'white' }} />
                </FooterIcon>
              </Link>
              <Link
                href="https://www.instagram.com/namastenelamangala/?hl=en"
                target="_blank"
              >
                <FooterIcon>
                  <InstagramIcon style={{ color: 'white' }} />
                </FooterIcon>
              </Link>
              <Link
                href="https://www.facebook.com/p/Namaste-Nelamangala-100078646553519/"
                target="_blank"
              >
                <FooterIcon>
                  <FacebookIcon style={{ color: 'white' }} />
                </FooterIcon>
              </Link>
            </FooterItem>
          </Grid>
          

          
        </Grid>
                <Grid
          container
          spacing={2}
          justifyContent={'space-evenly'}
          alignItems="center"
          alignContent={'center'}
            sx={{
    marginTop: '20px', // Adjust the value as needed for your design
  }}
        >
                  <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display={'flex'}
            justifyContent={'center'}
          >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Lexend', sans-serif",
                  fontSize: '10px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  textAlign: 'center',
                  color: 'white',

                  wordSpacing: '0.5em',
                }}
              >
               © 2024, SANSKRITI ENTERPRISES 
              </Typography>
          </Grid>
          </Grid>

        {/* </Container> */}
      </FooterContainer>
    </Stack>
  );
};

export default Footer;
