'use client';
import EnquiryPopUp from '@/components/popupComponents/EnquiryPopup';
import IMAGES from '@/utils/validations/ImagePaths';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';
import Image from 'next/image';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';

const StyledList = styled(List)({
  padding: '0',
  margin: '0',
  fontFamily: 'Manrope, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: 0,
  textAlign: 'center',
  textDecorationColor: 'rgba(255, 255, 255, 1)',
});

const Header = ({ categoriesRef, mapRef, videosRef, aboutRef, contactRef }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const headerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const searchParams = useSearchParams();
  const scrollRef = searchParams.get('scrollRef');
  const pathname = usePathname();
  const router = useRouter();

  const scrollToRef = useCallback(
    ref => {
      if (ref.current && headerRef.current) {
        window.scrollTo({
          top: ref.current.offsetTop - headerRef.current.clientHeight,
          behavior: 'smooth',
        });
      }
    },
    [headerRef]
  );

  useEffect(() => {
    if (scrollRef === 'mapRef') {
      scrollToRef(mapRef);
    } else if (scrollRef === 'categoriesRef') {
      scrollToRef(categoriesRef);
    } else if (scrollRef === 'videosRef') {
      scrollToRef(videosRef);
    } else if (scrollRef === 'aboutRef') {
      scrollToRef(aboutRef);
    } else if (scrollRef === 'contactRef') {
      scrollToRef(contactRef);
    } else {
      console.log('hi working');
    }
  }, [
    mapRef,
    scrollToRef,
    contactRef,
    aboutRef,
    videosRef,
    categoriesRef,
    scrollRef,
  ]);

  const handleClick = () => {
    setIsPopupOpen(true);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        setIsSticky(window.scrollY > headerRef.current.clientHeight);
      }
    }; // Adjust debounce delay as needed

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const Placeholder = () => (
    <div style={{ height: isSticky ? headerRef.current.clientHeight : 0 }} />
  );

  return (
    <Stack>
      <AppBar
        position={isSticky ? 'fixed' : 'static'}
        sx={{
          backgroundImage: 'linear-gradient(to right, #FFB300, #C62828)',
          transition: 'top 0.3s',
        }}
        ref={headerRef}
      >
        <Toolbar>
          {isMobile && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
                sx={{
                  background: 'linear-gradient(to right, #FFB300, #C62828)',
                  padding: '1rem',
                }}
              >
                <StyledList>
                  <List>
                    <ListItem
                      button
                      onClick={() => {
                        handleDrawerClose();
                        console.log(pathname, 'pathname');
                        if (pathname === '/') scrollToRef(mapRef);
                        else {
                          const queryParams = new URLSearchParams({
                            scrollRef: scrollRef,
                          }).toString();

                          router.push(`/?${queryParams}`);
                        }
                      }}
                    >
                      <ListItemText primary="Map View" />
                    </ListItem>
                    {/* <ListItem button onClick={() => { handleDrawerClose(); scrollToRef(categoriesRef); }}>
                      <ListItemText primary="Category" />
                    </ListItem> */}
                    <ListItem
                      button
                      onClick={() => {
                        handleDrawerClose();
                        console.log(pathname, 'pathname');
                        if (pathname === '/') scrollToRef(categoriesRef);
                        else {
                          const queryParams = new URLSearchParams({
                            scrollRef: scrollRef,
                          }).toString();

                          router.push(`/?${queryParams}`);
                        }
                      }}
                    >
                      <ListItemText primary="Category" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        handleDrawerClose();
                        console.log(pathname, 'pathname');
                        if (pathname === '/') scrollToRef(videosRef);
                        else {
                          const queryParams = new URLSearchParams({
                            scrollRef: scrollRef,
                          }).toString();

                          router.push(`/?${queryParams}`);
                        }
                      }}
                    >
                      <ListItemText primary="Recent Videos" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleDrawerClose();
                        console.log(pathname, 'pathname');
                        if (pathname === '/') scrollToRef(aboutRef);
                        else {
                          const queryParams = new URLSearchParams({
                            scrollRef: scrollRef,
                          }).toString();

                          router.push(`/?${queryParams}`);
                        }
                      }}
                    >
                      <ListItemText primary="About Us" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleDrawerClose();
                        console.log(pathname, 'pathname');
                        if (pathname === '/') scrollToRef(contactRef);
                        else {
                          const queryParams = new URLSearchParams({
                            scrollRef: scrollRef,
                          }).toString();

                          router.push(`/?${queryParams}`);
                        }
                      }}
                    >
                      <ListItemText primary="Feedback" />
                    </ListItem>
                  </List>
                </StyledList>
              </Drawer>
            </>
          )}
          

 <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexGrow: 1, // Ensures the title is centered
      }}
    >
      <Image src={IMAGES?.NN} alt="nn" height={50} width={50} />
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Manrope, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '22px',
          color: '#E0E0E0',
        }}
      >
        Namaste Nelamangala
      </Typography>
    </Box>
          


          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Manrope, sans-serif',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '22px',
              letterSpacing: 0,
              textAlign: 'center',
              color: '#E0E0E0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!isMobile && (
              <>
                <Button
                  color="inherit"
                  onClick={() => {
                    console.log(pathname, 'pathname');
                    if (pathname === '/') scrollToRef(mapRef);
                    else {
                      const queryParams = new URLSearchParams({
                        scrollRef: 'mapRef',
                      }).toString();

                      router.push(`/?${queryParams}`);
                    }
                  }}
                >
                  Map View
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    console.log(pathname, 'pathname');
                    if (pathname === '/') scrollToRef(categoriesRef);
                    else {
                      const queryParams = new URLSearchParams({
                        scrollRef: 'categoriesRef',
                      }).toString();

                      router.push(`/?${queryParams}`);
                    }
                  }}
                >
                  Category
                </Button>
                {/* <Button color="inherit" onClick={() => scrollToRef(videosRef)}>
                  Recent Videos
                </Button> */}
                <Button
                  color="inherit"
                  onClick={() => {
                    console.log(pathname, 'pathname');
                    if (pathname === '/') scrollToRef(videosRef);
                    else {
                      const queryParams = new URLSearchParams({
                        scrollRef: 'videosRef',
                      }).toString();

                      router.push(`/?${queryParams}`);
                    }
                  }}
                >
                  Recent Videos
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    console.log(pathname, 'pathname');
                    if (pathname === '/') scrollToRef(aboutRef);
                    else {
                      const queryParams = new URLSearchParams({
                        scrollRef: 'aboutRef',
                      }).toString();

                      router.push(`/?${queryParams}`);
                    }
                  }}
                >
                  About Us
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    console.log(pathname, 'pathname');
                    if (pathname === '/') scrollToRef(contactRef);
                    else {
                      const queryParams = new URLSearchParams({
                        scrollRef: 'contactRef',
                      }).toString();

                      router.push(`/?${queryParams}`);
                    }
                  }}
                >
                  Feedback
                </Button>
              </>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
              sx={{
                backgroundColor: '#FFD317',
                color: '#000000',
                textAlign: 'center',
                alignItems: 'center',
                margin: '0 1rem',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: '#000000',
                  color: '#FFD317',
                },
              }}
            >
              Enquiry
            </Button>
          </Typography>
        </Toolbar>
      </AppBar>
      <Placeholder />
      <EnquiryPopUp
        isDialogOpened={isPopupOpen}
        handleCloseDialog={() => {
          setIsPopupOpen(false);
        }}
      />
    </Stack>
  );
};

export default Header;
